import React,{ useState,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import Accordion from 'react-bootstrap/Accordion';
import logo from '../assets/images/logo.png';
import bannerbtm from '../assets/images/banner-btm.svg';
import wnft1 from '../assets/images/wnft1.png';
import wnft2 from '../assets/images/wnft2.png';
import wnft3 from '../assets/images/wnft3.png';
import hiwshape from "../assets/images/hiw-shape.svg";
import step1 from "../assets/images/step1.gif";
import step2 from "../assets/images/step2.gif";
import step3 from "../assets/images/step3.gif";
import step4 from "../assets/images/step4.gif";
import step5 from "../assets/images/step5.gif";
import nfticon from "../assets/images/ss.jpg";
import bc1 from "../assets/images/bc1.png";
import bc2 from "../assets/images/bc2.png";
import bc3 from "../assets/images/bc3.png";
import bc4 from "../assets/images/bc4.png";
import loader from "../assets/images/loader.svg";
import '../assets/css/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faPhone} from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faTwitter,faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { Link as SLink, animateScroll as scroll } from "react-scroll";
import axios from "../API/axios";
const defaultContact= {name:"", lastName:"",email:"",phone:"",message:""}
const Home = (props)=>{
    const [fixHeader,setFixHeader] = useState(false);
    const [openMenu,setOpenMenu] = useState(false);
    const [preloader,setPreLoader] =useState(true);
    const [showErr,setShowErr] = useState(false);
    const [errText,setErrText] = useState('');
    const [contact,setContact] = useState(defaultContact);
    const [submitted,setSubmitted]=useState(false);
    const [formloader,setFormloader]=useState(false);

    useEffect(() => {
        setTimeout(()=>setPreLoader(false),3000);
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);   

    const handleScroll = (event) => {
       var scroll = window.scrollY;
        var box = document.querySelector(".header-text").clientHeight;
        var header = document.querySelector('header').clientHeight;
        //console.log(`box height ${box} header ${header}`);
        //console.log("scroll event " + scroll);
        if (scroll >= box - header) {
            setFixHeader(true);
          } else {
            setFixHeader(false);
          }
      };
    const SubmitContact = (event) => {
        event.preventDefault();
        if (contact.name.trim() == "" || contact.name.trim() == "" || contact.lastName.trim() == ""
            || contact.phone.trim() == "" || contact.message.trim() == "") {
            setErrText("All fields are mandatory");
            setShowErr(true);
            return;
        }
        const validEmail = /^([a-zA-Z0-9_\.'\-])+@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})$/;

        if (!validEmail.test(contact.email.trim())) {
          setErrText("Enter a valid email");
          setShowErr(true);
          return;
        }

        const validPhone =  /^[0-9]+$/g

        if (!validPhone.test(contact.phone.trim())) {
          setErrText("Enter a valid phone number");
          setShowErr(true);
          return;
        }
        setErrText("");
        setShowErr(false);

        //console.log(contact);
        setFormloader(true);
        axios.post("/contact/Submit", {contact})
        .then(res => {
            setFormloader(false);
          const { data } = res;
        
          if (data.message === "success") {
            setContact(defaultContact);
            setSubmitted(true);
            setTimeout(()=>setSubmitted(false),3000);
          }
          else {
            setErrText(data.message);
            setShowErr(true);
          }
        }).catch(err => {
            setFormloader(false);
            setErrText("Something went wrong!");
            setShowErr(true);
            console.error(err)
        });
    }
    return(
        <>
        {preloader&&   
                <div id="js-preloader" className="js-preloader">
                    <div className="preloader-inner">
                    <img src={logo} />
                        {/* <span className="dot"></span>
                        <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div> */}
                    </div>
                </div>
        }
        <div>
                <header className={fixHeader ? "header-area header-sticky background-header":"header-area header-sticky"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <nav className="main-nav">
                                    {/* <!-- ***** Logo Start ***** --> */}
                                    <Link to="/" className="logo">
                                         <img src={logo}/>                                    
                                    </Link>
                                    {/* <a href="index.html" className="logo">
                                        <img src={logo}/>
                                    </a> */}
                                    {/* <!-- ***** Logo End ***** -->
                                    <!-- ***** Menu Start ***** --> */}
                                    <ul className={openMenu?"nav show":"nav"}>
                                        {/* <!-- <li className="scroll-to-section"><a href="#top" className="active">Home</a></li> --> */}
                                        <li className="scroll-to-section"><SLink to="whynft" smooth={true} duration={500} onClick={()=> setOpenMenu(false)}>Why NFTs</SLink></li>
                                        <li className="scroll-to-section"><SLink to="hiw" smooth={true} duration={500} onClick={()=> setOpenMenu(false)}>How It Works</SLink></li>
                                        <li className="scroll-to-section"><SLink to="contact" smooth={true} duration={500} onClick={()=> setOpenMenu(false)}>Contact</SLink></li>
                                        <li  className="oll-to-section"><div className="main-red-button"><Link to="/verify">Verify Now</Link></div></li>
                                    </ul>
                                    <a className={openMenu?'menu-trigger active': 'menu-trigger'} onClick={()=> setOpenMenu(!openMenu)}>
                                        <span>Menu</span>
                                    </a>
                                    {/* <!-- ***** Menu End ***** --> */}
                                </nav>
                            </div>
                        </div>
                    </div>

                </header>
                <div className="main-banner" id="top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-12 align-self-center">
                                        <div className="left-content header-text">
                                            {/* <!-- <h6>Welcome to Space Dynamic</h6> --> */}
                                            <h2>The first platform that enables Certifications Verification<em></em><span></span></h2>
                                            
                                            <div className="down-buttons">
                                                <div className="main-blue-button-hover">
                                                    <a href="#hiw">How It Works</a>
                                                </div>
                                                {/* <!-- <div className="call-button">
                      <a className="scroll-to-section" href="#hiw"><i className="fa fa-play"></i> How It Works ?</a>
                    </div> --> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-bg-shape">
                        <div className="round-shape-1"> <svg fill="#fff" height="800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 747.2 726.7">
                            <path d="M539.8 137.6c98.3 69 183.5 124 203 198.4 19.3 74.4-27.1 168.2-93.8 245-66.8 76.8-153.8 136.6-254.2 144.9-100.6 8.2-214.7-35.1-292.7-122.5S-18.1 384.1 7.4 259.8C33 135.6 126.3 19 228.5 2.2c102.1-16.8 213.2 66.3 311.3 135.4z"></path>
                        </svg></div>
                        <div className="round-shape-2"></div>
                    </div>

                    <div className="banner-shape">
                        <img className="bs-desk" src={bannerbtm} />
                        <img className="bs-mob" src={bannerbtm} />
                    </div>
                </div>
                <div id="whynft" className="our-portfolio section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="section-heading">
                                    <h2>Why NFTs<em></em><span></span></h2>
                                    <p>Discover why you should immediately provide your students with NFT certificates</p>
                                </div>
                            </div>
                        </div>
                        <div className="pt-80">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6 col-md-12">
                                        <a>
                                            <div className="item">
                                                <div className="showed-content">
                                                    <img src={wnft1} alt="" style={{ background: "transparent" }} />
                                                    <h4>Make your course unique</h4>
                                                    <p>Issuing a tamper-proof certificate adds authority, prestige and credibility to the course, increasing the chances that students will choose it.</p>
                                                    <h5>Increase the chances that students will choose the course.</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 col-md-12">
                                        <a>
                                            <div className="item">
                                                <div className="showed-content">
                                                    <img src={wnft2} alt="" style={{ background: "transparent" }} />
                                                    <h4>Issue verifiable certificates</h4>
                                                    <p>Students will receive an NFT certificate which can provide a verifiable and unique proof of authenticity and completion of the course, protecting them from potential fraud or forgery.</p>
                                                    <h5>Protect students from potential fraud or forgery.</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 col-md-12">
                                        <a>
                                            <div className="item">
                                                <div className="showed-content">
                                                    <img src={wnft3} alt="" style={{ background: "transparent" }} />
                                                    <h4>Improve recruitment</h4>
                                                    <p>Once your certificate has been recorded on the blockchain, anyone can easily verify the student's expertise and learned skills. 78% of job recruiters say that this technology will be a game changer for the HR/any industry.</p>
                                                    <h5>A game changer for HR and recruiters.</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>


                </div>

                <div id="hiw" className="our-services section">
                <div className="hiw">
                        <img src={hiwshape} />
                    </div>
                <div className="section-heading hiwsh">
                                    <h2> How it works<em></em> <span> </span></h2>
                                    <div className="marquee-main">
                                    <svg id="svg" viewBox="300 0 800 200" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" id="text-curve" d="M-11 168.5C118 178 221 87.9999 536.566 148.644C595.489 159.967 698.5 185.5 863.275 148.644C990.5 120.187 1130.5 122.5 1208 148.644">
                                        </path>
                                        <text x="0">

                                            <textPath href="#text-curve">
                                                <tspan dx="50" className="pt" >5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt" >5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt" >5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                <tspan dx="50" className="pt">5 Easy Steps</tspan>
                                                
                                            </textPath>
                                            <animate attributeName="x" dur="100s" values="-1800;0" repeatDur="indefinite" repeatCount="indefinite"></animate>
                                        </text>
                                    </svg>
                                    </div>
                                </div>

                    <div className="container">
                        <div className="row pb-120">
                            <div className="col-lg-12">
                              
                            </div>
                            {/* <!--         <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.25s">
  <div className="top-dec">
    <img src="assets/images/blog-dec.png" alt="">
  </div>
</div> --> */}
                        </div>

                        <div className="row pb-120 hiw1">
                            <div className="col-lg-6 align-self-center desk-gif">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="left-image">
                                        <img src={step1} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="section-heading">
                                        <h2 style={{textTransform:"inherit"}}>NFTs sent by the institutions</h2>
                                        <img className="mob-gif" src={step1} alt="" />
                                        <p>The institution will issue the NFT certificate, which will be sent directly to each student's wallet. This certificate, being loaded on the blockchain, is publicly visible. Each certificate will have a unique ID, which will be used to verify its authenticity.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pb-120">
                            <div className="col-lg-6">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="section-heading">
                                        <h2 style={{textTransform:"inherit"}}>NFTs shareable on social platforms</h2>
                                        <img className="mob-gif" src={step2} alt="" />
                                        <p>The students will be able to include the NFT certificate in their resume or on their LinkedIn profile.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center desk-gif">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="left-image">
                                        <img src={step2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pb-120">
                            <div className="col-lg-6 align-self-center desk-gif">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="left-image">
                                        <img src={step3} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="section-heading">
                                        <h2 style={{textTransform:"inherit"}}>Certificates verifiable in 2 clicks</h2>                                        
                                        <img className="mob-gif" src={step3} alt="" />
                                        <p>This is where Umboz comes in: our platform allows you to verify NFT certificates in just one clicks. Simply enter the collection ID of the institution that issued the certificate and the NFT certificate ID of the student.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pb-120">
                            <div className="col-lg-6">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="section-heading">
                                        <h2 style={{textTransform:"inherit"}}>All info are public</h2>                                        
                                        <img className="mob-gif" src={step4} alt="" />
                                        <p>The collection ID is located on the website of the institution that issued the certificate. The NFT certificate ID can be found on the student's resume, on the institution's Opensea account, or on their LinkedIn account.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center desk-gif">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="left-image">
                                        <img src={step4} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pb-120">
                            <div className="col-lg-6 align-self-center desk-gif">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="left-image">
                                        <img src={step5} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="section-heading">
                                        <h2 style={{textTransform:"inherit"}}>User friendly</h2>
                                        <img className="mob-gif" src={step5} alt="" />
                                        <p>The platform will automatically retrieve the data from the blockchain. If the user has successfully completed the course, you will be able to check the official certificate issued by the institution.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="portfolio" className="our-portfolio section about-nft">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-heading">
                                    <h2>The power of NFTs through Umboz <em></em><span></span></h2>
                                    <p>Discover why you should immediately provide your students with NFT certificates.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="left-image">
                                    <img src={nfticon} alt="" />
                                </div>
                            </div>
                        </div>


                        </div>


                        {/* <!--   <div className="row">
        <div className="col-lg-12 offset-lg-0">
          <div className="section-heading">
            <h2>The power of NFTs through Umboz <em></em><span></span></h2>
            <p>Discover why you should immediately provide your students with NFT certificates</p>
          </div>
        </div>
      </div> --> */}
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0">
                                <div className="col-lg-12">
                                    <p className="pi">Platform Integrations</p>
                                    <div className="pt-50 pb-120">
                                        <a className="bc1"><img src={bc1} alt="Workspace Desktop" /></a>
                                        <a className="bc2"><img src={bc2} alt="Workspace Desktop" /></a>
                                        <a className="bc3"><img src={bc3} alt="Workspace Desktop" /></a>
                                        <a className="bc4"><img src={bc4} alt="Workspace Desktop" /></a>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="right-list">
                                        <ul>
                                            <li>
                                                <div className="left-content align-self-center">
                                                    <span><FontAwesomeIcon icon={faCheck} /></span>
                                                    <p>Available on Opensea</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left-content align-self-center">
                                                    <span><FontAwesomeIcon icon={faCheck} /></span>
                                                    <p>Available on 170+ wallets</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left-content align-self-center">
                                                    <span><FontAwesomeIcon icon={faCheck} /></span>
                                                    <p>Publicly visible</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left-content align-self-center">
                                                    <span><FontAwesomeIcon icon={faCheck} /></span>
                                                    <p>Gas fees free</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left-content align-self-center">
                                                    <span><FontAwesomeIcon icon={faCheck} /></span>
                                                    <p>No code needed</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left-content align-self-center">
                                                    <span><FontAwesomeIcon icon={faCheck} /></span>
                                                    <p>Easy to use</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                <div id="faq" className="our-portfolio section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="section-heading">
                                    <h2>Frequently Asked Questions<span> </span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="container-lg">
                                <div className="row">
                                    <div className="col-lg-12 offset-lg-0">
                                        <Accordion flush>
                                            <Accordion.Item className="card" eventKey="0">
                                                <Accordion.Header bsPrefix='' className="card-header">How do I know if the data I see are true?</Accordion.Header>
                                                <Accordion.Body>
                                                The data has been uploaded to the blockchain directly by the issuing institution. They are not held by any third party and cannot be tampered with or modified in any way.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item className="card" eventKey="1">
                                                <Accordion.Header className="card-header">How does blockchain work?</Accordion.Header>
                                                <Accordion.Body>
                                                A Blockchain is an immutable and distributed ledger on which various types of information can be stored, these are publicly visible and immediately accessible.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item  className="card" eventKey="2">
                                                <Accordion.Header className="card-header">What is an NFT?</Accordion.Header>
                                                <Accordion.Body>
                                                NFT stands for "non-fungible token" which refers to a unique digital content that cannot be replaced by another, thereby attesting to the authenticity, uniqueness and ownership of a digital object. NFTs differ from fungible goods, such as the Euro currency, where a €10 can be exchanged with another €10. Because each NFT is unique, it can be used to authenticate the ownership of a digital asset such as a certificate or diploma.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item className="card" eventKey="3">
                                                <Accordion.Header className="card-header">How long does an NFT last?</Accordion.Header>
                                                <Accordion.Body>
                                                Once an NFT is generated, it is immutable and has no expiration date.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item className="card" eventKey="4">
                                                <Accordion.Header className="card-header">What is the purpose of having an NFT certificate?</Accordion.Header>
                                                <Accordion.Body>
                                                An NFT certifies the ownership of an asset. By generating a certificate or diploma through NFT technology, every student can easily demonstrate that they have actually attended and/or completed a course. At the same time, thanks to Umboz, anyone can easily verify that the certificate has been issued/provided. This is a huge advantage for the entire job ecosystem.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item className="card" eventKey="5">
                                                <Accordion.Header className="card-header">How can I issue NFTs for my course?</Accordion.Header>
                                                <Accordion.Body>
                                                We will do it for you. Send us a message by filling out the form below: we will send you an email within 24 hours.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item className="card" eventKey="6">
                                                <Accordion.Header className="card-header">What does Umboz do?</Accordion.Header>
                                                <Accordion.Body>
                                                Umboz is a platform that interacts with the blockchain. Its aim is to verify (for free), the actual attainment of the certificate by the desired subject in just one click. Umboz is a proprietary platform of Wallecto S.R.L
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>

                            {/* <!--         <div className="col-lg-3 col-sm-6">
          <a href="#">
            <div className="item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.3s">
              <div className="hidden-content">
                <h4>SEO Analysis</h4>
                <p>Lorem ipsum dolor sit ameti ctetur aoi adipiscing eto.</p>
              </div>
              <div className="showed-content">
                <img src="assets/images/portfolio-image.png" alt="">
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-sm-6">
          <a href="#">
            <div className="item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.4s">
              <div className="hidden-content">
                <h4>Website Reporting</h4>
                <p>Lorem ipsum dolor sit ameti ctetur aoi adipiscing eto.</p>
              </div>
              <div className="showed-content">
                <img src="assets/images/portfolio-image.png" alt="">
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-sm-6">
          <a href="#">
            <div className="item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="hidden-content">
                <h4>Performance Tests</h4>
                <p>Lorem ipsum dolor sit ameti ctetur aoi adipiscing eto.</p>
              </div>
              <div className="showed-content">
                <img src="assets/images/portfolio-image.png" alt="">
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-sm-6">
          <a href="#">
            <div className="item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.6s">
              <div className="hidden-content">
                <h4>Data Analysis</h4>
                <p>Lorem ipsum dolor sit ameti ctetur aoi adipiscing eto.</p>
              </div>
              <div className="showed-content">
                <img src="assets/images/portfolio-image.png" alt="">
              </div>
            </div>
          </a>
        </div> --> */}
                        </div>
                    </div>
                </div>

                <div id="contact" className="contact-us section">
                  
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 align-self-center">
                                <div className="section-heading">
                                    {/* <!-- <img src="assets/images/success.png"> --> */}
                                        <h2>With Umboz, you can</h2>
                                        <p>✔ Generate NFT certificates without using a code.<br />✔ Verify NFT certificates with just one click.<br />✔ Improve the job ecosystem.</p>
                                            <div className="phone-info">
                                                <h4>Any Query Call Us <span><FontAwesomeIcon icon={faPhone} /> <a>+39 34671 24484</a></span></h4>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div id="contact1">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <fieldset>
                                                            <input type="name" name="name" id="firstname" value={contact.name} onChange={(e) => { setShowErr(false); setContact({ ...contact, name: e.target.value }) }} placeholder="First Name" autocomplete="on" required />
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <fieldset>
                                                            <input type="surname" name="surname" id="Lastname" value={contact.lastName} onChange={(e) => { setShowErr(false); setContact({ ...contact, lastName: e.target.value }) }} placeholder="Last Name" autocomplete="on" required />
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <fieldset>
                                                            <input type="text" name="email" id="email" value={contact.email} onChange={(e) => { setShowErr(false); setContact({ ...contact, email: e.target.value }) }} pattern="[^ @]*@[^ @]*" placeholder="Your Email" required="" />
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-lg-12">
                                            <fieldset>
                                                <input type="number" name="number" id="number"
                                                    onKeyDown={(e) => {
                                                        var key = e.charCode || e.keyCode;
                                                        // Disable Up and Down Arrows on Keyboard
                                                        if (key == 38 || key == 40) {
                                                            e.preventDefault();
                                                        } else {
                                                            return;
                                                        }
                                                    }}
                                                    value={contact.phone}
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={(e) => { setShowErr(false); setContact({ ...contact, phone: e.target.value }) }} placeholder="Phone Number" required="" />
                                            </fieldset>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <fieldset>
                                                            <textarea name="message" type="text" className="form-control" id="message" value={contact.message} onChange={(e) => { setShowErr(false); setContact({ ...contact, message: e.target.value }) }} placeholder="Leave A Message Here" required=""></textarea>
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-lg-12">
                                                    {showErr && <div className="error-msg"><p>{errText}</p></div>}
                                                        <fieldset>
                                                            <button type="submit" id="form-submit" onClick={SubmitContact} className="main-button ">{formloader? <img src={loader} />:<span>Submit</span>}</button>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                {submitted && <div className="success-msg"><p>Thanks for writing us.We will get back to you shortly.</p></div>}
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                <footer className="pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="container">
                                    <div className="row f-top">
                                        <div className="col-lg-3 ftl">
                                            <a href=""><img src={logo} /></a>
                                        </div>
                                        <div className="col-lg-6 ftc">
                                            {/* <a href="#">Terms & Conditions</a> */}
                                            <a href="https://www.iubenda.com/privacy-policy/57938354" target="_blank">Privacy Policy</a>
                                        </div>
                                        <div className="col-lg-3 ftr">
                                            <a href="https://www.instagram.com/wallecto/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                                            <a href="https://www.linkedin.com/company/wallecto" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            <a href="https://twitter.com/wallectosrl" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 wow fadeIn" data-wow-dataw="1s" data-wow-delay="0.25s">
                                <div className="containter">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <p className="ftl" style={{fontSize:"14px"}}>WALLECTO S.R.L. | Registered office in Viale Libertà 61, 27100, Pavia | VAT no.: 02873250183 | Pavia Company Register no. 307592</p>
                                        </div>
                                        <div className="col-lg-6">
                                             <p className="ftr" style={{fontSize:"14px"}}>Copyright ©️ Umboz 2023. A <a href="https://wallecto.com/en/" target="_blank">Wallecto</a> platform. All rights reserved.</p>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
        </div>

        </>
    )

}
export default Home;