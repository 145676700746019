import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route,Navigate} from 'react-router-dom';
import Home  from './pages/home'
import Verify from './pages/verify';
function App() {
  return (
    <Router>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/verify" element={ <Verify />} />
    </Routes>
  </Router>
  );
}

export default App;
