import React,{ useState,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import logo from '../assets/images/logo.png';
import { Link } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
// NFT imports
import { ethers } from "ethers";
import { use } from "@maticnetwork/maticjs";
import { Web3ClientPlugin } from '@maticnetwork/maticjs-ethers';
import abi_ from "../nft/abi.json";
import abi_collection from "../nft/abi_collection.json";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo} from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faTwitter,faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
//verify smart contract address
const ver_smart_address = "0x78cb560BA072E38F388223c02e6a525F883531C1";
const logo_link2 = "";

//connecting on polygon scan using the private key of an account
const your_private_key_string = "7f61efa648d7be975732280a6218076747d7d92bc9ecabe416bac14100545e94";
const provider = new ethers.providers.JsonRpcProvider("https://polygon-rpc.com");
const signer = new ethers.Wallet(your_private_key_string, provider);
const ver_contr = new ethers.Contract( ver_smart_address , abi_ , signer );

///GET THE COLLECTION ADDRESS AND ID FROM link
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const urlParams2 = new URLSearchParams(queryString);
const _collec_url = urlParams.get('_collec_url'); //link example ?_collec_url=0x039e2e1DdaCA62Eb760b28C2c18e0f46eB165E26
const _ID_url = urlParams2.get('_ID_url');

const Verify = (props) => {
    const [loading, setLoading] = useState(false);
    const [verifyForm, setVerifyForm] = useState({ collection: '', nftId: '' });
    const [showErr, setShowErr] = useState(false);
    const [errText, setErrText] = useState('');
    const [showVerifier,setShowVerifier]=useState(true);
    const [showAlert,setShowAlert] = useState(false);
    const [alert,setAlert] = useState({variant:'',msg:''});
    const [preloader,setPreLoader] =useState(true);
    const [openMenu,setOpenMenu] = useState(false);
    // NFT 
    const [logo_link, setlogo_link] = useState(false);
    const [name_id, set_nameID] = useState(false);
    const [cert_coll, set_cert_coll] = useState(false);
    const [link_website, set_link_website] = useState(false);
    const [metadata_global, set_metadata_global] = useState(false);
    const [metadata, set_metadata] = useState(false);
    const [_address_coll, set_address_col] = useState();
    const [mint_2_ID, set_mint_ID] = useState();
    const[falseCollection,setFalseCollection] = useState(false);
    const[falseID,setFalseID]=useState(false);
    useEffect(() => {        
        setTimeout(()=>setPreLoader(false),3000);
    }, []);  
    // install ethers plugin
    use(Web3ClientPlugin);
    // function useful to obtain the link of logo from the blockchain
    const get_logo = async (_coll_address) => {
        //let _link_1 = ver_contr.get_link(_coll_address).then(function(result){console.log("non hai i dati: " + result); return result});

        await ver_contr.get_link(_coll_address).then(function (result) {
            //console.log(result);
            return result;
        });
    }

    //// function to check if the collection is a fake or not
    const valid_collection = (valid_address, test_dd) => {
        var count_valid = 0;
        for (let i = 0; i <= valid_address.length; i++) {
            if (valid_address[i] == test_dd) {
                count_valid = count_valid + 1
            }
            //console.log("cycle: " + i);
        }
        if (count_valid > 0) {
            return true;
        }
        else { return false }
    }

    //// function to check
    const check_id = (id__, _mint_ID__) => {
        if (id__ > _mint_ID__) {

            console.log("id: " + id__ + " MINT_ID: " + _mint_ID__);
            console.log("false");
            return false

        } else if (id__ <= _mint_ID__) {

            console.log("id: " + id__ + " MINT_ID: " + _mint_ID__);
            console.log("true")
            return true
        }

    }


    const VerifyCertificate = async (event) => {
        try{
        console.log("form submit");
        // clear data
        set_nameID(false);
        setlogo_link(false);
        set_address_col(false);
        set_cert_coll(false);
        set_link_website(false);
        set_mint_ID(false);
        set_metadata(false);
        set_metadata_global(false);
        setFalseCollection(false);
        setFalseID(false);
        if (verifyForm.collection.trim() === "" || verifyForm.nftId.trim() === "") {
            setErrText("All fields are mandatory");
            setShowErr(true);
            return;
        }
        setLoading(true);
        // obtain the collection address
        var collection = verifyForm.collection;

        set_address_col("test on button: " + a);
        ///verify in the collection is verified
        var a = await ver_contr.get_valid();
        const vv = valid_collection(a, collection);
        set_address_col(vv);
        //console.log("check collection: "+ vv + " . " + _address_coll);
        if(!vv){
        setFalseCollection(true);
        setLoading(false);
        }
        set_nameID(false);
        setlogo_link(false);
        //console.log(e);

        ///obtain the logo link -- se non è valido non dovrebbe attivare il link
        var logo_link2_ = await ver_contr.get_link(collection);
        //console.log("bla", logo_link2_);
        setlogo_link(logo_link2_);

        var name_id = await ver_contr.get_name(collection);
        set_nameID(name_id);
        //console.log(name_id);

        set_cert_coll(collection);
        //console.log("collection address: " + cert_coll);
        let website_link_ = await ver_contr.get_link_website(collection);
        //console.log("link website: " + website_link_);
        set_link_website(website_link_);
        var id = verifyForm.nftId;
        // var collection = returnCollection();

        ///inizialize collection contract
        const coll_contr = new ethers.Contract(collection, abi_collection, signer);


        ///// minted Token
        var _mint_ID = await coll_contr.totalSupply();

        const aia = check_id(parseInt(id), parseInt(_mint_ID));
        console.log("verify true false:" + aia);
        set_mint_ID(aia);
        if(!aia){
        setFalseID(true);
        setLoading(false);
        }
        /// extract metadata link for the single token ID
        var json_link = await coll_contr.tokenURI(id);
        axios.get()
        ////extract json file from link
        fetch(json_link,{mode:"cors"})
        .then(res => res.json())
        .then((out) => {
            }).catch(err => { throw err });
        const meta_fetch = await fetch(json_link);
        var metadata_fetch = await meta_fetch.json();
        //console.log("--- Before --");
        //console.log(meta_fetch);
        var metadata_str = JSON.stringify(metadata_fetch);
        const metadata_json = JSON.parse(metadata_str);
        set_metadata(metadata_json.attributes[0].value);
        set_metadata_global(metadata_json);
        //console.log(metadata_json);
        setLoading(false);
        if(aia){
            setShowVerifier(false);
        }
    }
        catch(error){
        setLoading(false);
        setAlert({variant:"Danger", msg:"Error while fetching NFT records"});
        setShowAlert(true);
        setTimeout(()=>{setShowAlert(false)},3000);
         console.error(error);
    }
    }

    const reset = ()=>{
        // clear data
        set_nameID(false);
        setlogo_link(false);
        set_address_col(false);
        set_cert_coll(false);
        set_link_website(false);
        set_mint_ID(false);
        set_metadata(false);
        set_metadata_global(false);
        setShowVerifier(true);
        setVerifyForm({collection:'',nftId:''});
        setFalseCollection(false);
        setFalseID(false);
    }
    
    return (
        <>
            {preloader&&   
                <div id="js-preloader" className="js-preloader">
                    <div className="preloader-inner">
                    <img src={logo} />
                        {/* <span className="dot"></span>
                        <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div> */}
                    </div>
                </div>
        }
            {loading && (
                <div className="bg-blur">
                    <div className="preloader-inner">
                    <img src={logo} />
                        {/* <span className="dot"></span>
                        <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div> 
                        <p>Verifying Please Wait...</p>*/}
                    </div>
                </div>
            )}
           
            <header className="header-area header-sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav">
                                <Link to="/" className="logo">
                                    <img src={logo} />
                                </Link>
                                <ul className={openMenu?"nav show":"nav"}>
                                    <li><Link to="/" className="active">Back To Home</Link></li>
                                    <li className="scroll-to-section" style={{ display: "none" }}><div className="main-red-button"><a href="#">Verify Now</a></div></li>
                                </ul>
                                <a className={openMenu?'menu-trigger active': 'menu-trigger'} onClick={()=> setOpenMenu(!openMenu)}>
                                    <span>Menu</span>
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!-- ***** Header Area End ***** --> */}
            
            {showVerifier &&
            <div className="verify-main">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-6  offset-lg-3 certificate-verify-blk">
                                    <div id="contact2">
                                        <div>
                                            <h4 className="pb-30">Certificate Verifier</h4>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <fieldset>
                                                        <label>Institution's Collection ID</label>

                                                        <div className="tooltip" data-direction="bottom">
                                                            <div className="tooltip__initiator">
                                                                {/* <!-- element with tooltip hover call --> */}
                                                                <FontAwesomeIcon color="#8f8f8f" icon={faCircleInfo} />
                                                            </div>
                                                            <div className="tooltip__item">To find the collection ID you can check out the certifying institution's
                                                                website or look it up on their OpenSea account. The ID is usually a long alphanumeric code that starts with "0x".
                                                            </div>
                                                        </div>
                                                        <input type="text" name="text" id="" placeholder="Ex: 0x6e8ded6e03223ec3cd98c345cedd..." required="" onChange={(e) => { setShowErr(false); setVerifyForm({ ...verifyForm, collection: e.target.value.trim() }) }} />

                                                    </fieldset>
                                                </div>
                                                <div className="col-lg-12">
                                                    <fieldset>
                                                        <label>NFT ID</label>
                                                        <div className="tooltip" data-direction="bottom">
                                                            <div className="tooltip__initiator">
                                                                {/* <!-- element with tooltip hover call --> */}
                                                                <FontAwesomeIcon color="#8f8f8f" icon={faCircleInfo} />
                                                            </div>
                                                            <div className="tooltip__item">To find the candidate's NFT ID on their resume or LinkedIn, look for a
                                                                numerical code that can range from 1 to 10 digits. That's usually the NFT ID.
                                                            </div>
                                                        </div>
                                                        <input type="text" name="text" id="" placeholder="Ex. 15" required="" onChange={(e) => { setShowErr(false); setVerifyForm({ ...verifyForm, nftId: e.target.value.trim() }) }} />
                                                    </fieldset>
                                                </div>
                                                {showErr && <p className="err-txt">{errText}</p>}
                                                <div className="col-lg-12">
                                                    <fieldset>
                                                        <button type="submit" id="verify-btn" onClick={VerifyCertificate} className="main-button " style={{ width: "100%" }}>Verify</button>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                    {falseCollection &&
                        <div className="invalid-coll">
                            {"Please enter a valid collection"}
                        </div>

                    }

                    {falseID &&
                        <div className="invalid-id">
                            {"Please enter a valid NFT id"}
                        </div>
                    }
            </div>
             
}

            <ToastContainer position="top-end" className="p-3">
                <Toast
                    className="d-inline-block m-1"
                    bg={alert.variant.toLowerCase()}
                    onClose={() => setShowAlert(false)}
                    show={showAlert} delay={3000} autohide
                >

                    <Toast.Body className={alert.variant === 'Danger' && 'text-white'}>
                        {alert.msg}
                    </Toast.Body>
                </Toast>
            </ToastContainer>

{
    mint_2_ID &&
            <div className="view-certificate-blk">
            {_address_coll && 
                <div className="container">
                    {metadata &&
                    <div className="vcm">
                        <h4 className="pb-30">Certificate Details</h4>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="col-lg-6"><img src={metadata_global.attributes[7].value} /></div>
                                <div className="col-lg-6 candidate-info">
                                                    <ul className="site-info-main">
                                                        <li className="heading"><b>Institute Details</b></li>
                                                        {logo_link && <li><div className="cl"><img src={logo_link} /></div></li>}
                                                        {name_id && <li><div className="cl"><span>Name</span></div> - <div className="cr"><span>{name_id}</span></div></li>}
                                                        {link_website && <li><div className="cl"><span>Website</span></div> - <div className="cr"><span>{link_website}</span></div></li>}
                                                        
                                                    </ul>
                                                    <ul className="certificate-info-main">
                                                    <li className="heading"><b>Cerificate Details</b></li>
                                                        {metadata_global.attributes[0].trait_type.trim() != "" ? <li><div className="cl"><span>Name</span></div> - <div className="cr"><span>{metadata}</span></div></li> : ""}
                                                        {metadata_global.attributes[1].trait_type.trim() != "" ? <li><div className="cl"><span>Surname</span></div> - <div className="cr"><span>{metadata_global.attributes[1].value}</span></div></li> : ""}
                                                        {metadata_global.attributes[2].trait_type.trim() != "" ? <li><div className="cl"><span>Course</span></div> - <div className="cr"><span>{metadata_global.attributes[2].value}</span></div></li> : ""}
                                                        {metadata_global.attributes[3].trait_type.trim() != "" ? <li><div className="cl"><span>Date of emission</span></div> - <div className="cr"><span>{metadata_global.attributes[3].value}</span></div></li> : ""}
                                                        {metadata_global.attributes[4].trait_type.trim() != "" ? <li><div className="cl"><span>{metadata_global.attributes[4].trait_type}</span></div> - <div className="cr"><span>{metadata_global.attributes[4].value}</span></div></li> : ""}
                                                        {metadata_global.attributes[5].trait_type.trim() != "" ? <li><div className="cl"><span>{metadata_global.attributes[5].trait_type}</span></div> - <div className="cr"><span>{metadata_global.attributes[5].value}</span></div></li> : ""}

                                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12 pt-50">
                                <fieldset>
                                    <button type="submit" id="verify-again-btn" onClick={reset} className="main-button">Verify Again</button>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    }

                </div>
                }
            </div>
}

<footer className="pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="container">
                                    <div className="row f-top">
                                        <div className="col-lg-3 ftl">
                                            <a href=""><img src={logo} /></a>
                                        </div>
                                        <div className="col-lg-6 ftc">
                                            {/* <a href="#">Terms & Conditions</a> */}
                                            <a href="https://www.iubenda.com/privacy-policy/57938354" target="_blank">Privacy Policy</a>
                                        </div>
                                        <div className="col-lg-3 ftr">
                                            <a href="https://www.instagram.com/wallecto/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                                            <a href="https://www.linkedin.com/company/wallecto" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            <a href="https://twitter.com/wallectosrl" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                         

                            <div className="col-lg-12 wow fadeIn" data-wow-dataw="1s" data-wow-delay="0.25s">
                                <div className="containter">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <p className="ftl" style={{fontSize:"14px"}}>WALLECTO S.R.L. | Registered office in Viale Libertà 61, 27100, Pavia | VAT no.: 02873250183 | Pavia Company Register no. 307592</p>
                                        </div>
                                        <div className="col-lg-6">
                                             <p className="ftr" style={{fontSize:"14px"}}>Copyright ©️ Umboz 2023. A <a href="https://wallecto.com/en/" target="_blank">Wallecto</a> platform. All rights reserved.</p>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
        </>
    )
};

export default Verify;